import React from "react";

function MedicalPreference({ BackgroundCol, MedicalPreferenceData }) {
  return (
    <div
      className={`${
        BackgroundCol ? BackgroundCol : "bg-midBlue"
      }  flex items-center justify-center py-24 md:py-28 `}
    >
      <div className="grid grid-cols-1 md:grid-cols-2 max-w-screen-xl w-full lg:w-7/12 px-4 lg:px-0">
        <h1 className="text-3xl md:text-4xl text-white font-secondary font-bold pr-0 lg:pr-10 leading-8 lg:leading-[52px]">
          <span className=""></span>{" "}
          <span className="text-orange">Medical</span>{" "}
          <span className="">Interpretation Per Your Preference</span>
        </h1>
        <div className="flex items-center justify-center flex-col">
          {MedicalPreferenceData
            ? MedicalPreferenceData?.map(({ img_link, title, para }) => (
                <div className="mb-8">
                  <img src={img_link} alt="loading..." className="mt-6 mb-8" />
                  <h1 className="text-2xl font-secondary font-bold text-white mb-6">
                    {title}
                  </h1>
                  <p className="text-darkMist font-opensans">{para}</p>
                </div>
              ))
            : null}
        </div>
      </div>
    </div>
  );
}

export default MedicalPreference;
