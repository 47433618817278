export const MedicalInterData = [
  {
    title: "Hospitals",
    detail:
      "Hospitals need interpretation, now more than ever. If you're in a medical emergency, Tomedes interpreters can accommodate your needs by sending an onsite interpreter. Medical interpreting for hospitals are needed in cases of conversations within the hospital visit, such as medical rights, medical care, and more.",
  },
  {
    title: "Clinics",
    detail:
      "Clinical visits, either on telehealthcare or in the physical, need the assistance of medical interpreters to bridge the language or disability gap between the patient and the healthcare provider. You can opt for remote, onsite, or telephonic interpreting, at Tomedes.",
  },
  {
    title: "Private Practices",
    detail:
      "Medical businesses that have their own private practices need emergency care that can cater to all. With that in mind, medical interpreters are critically needed in that field, to convey the messages needed from patient to provider, as well as intercede for the patient to the health care workers.",
  },
  {
    title: "Specialized Treatment Centres ",
    detail:
      "For physical therapy, regenerative medicine, and diagnostic care in specialized treatment centres, on-site and remote interpreters have the capability to be a bridge between the institutions, the physicians and the patient. This service is needed to translate into more than 120 languages.",
  },
  {
    title: "Pharmacies",
    detail:
      "Getting a hold of medicine is an important aspect of a patient or individual's life, which is why medical interpreting for pharmacies is sorely needed for this field. Medical interpreters stand between critical medications and limited language proficiency patients who need the medication.",
  },
  {
    title: "Assisted Living Facilities",
    detail:
      "In home care like nursing homes, medical interpreters provide the special skill of oral translation for the patients. Medical interpretation can be used for all home care institutions, such as for the disabled or mentally incapacitated",
  },
];

export const MedicalPreferenceData = [
  {
    img_link: "",
    title: "On-site",
    para: "On-site medical interpretation, as per your preference, is available at Tomedes. On-site medical interpretation is the process of translating everything on site in all conversations, as if it were the speaker who said the words.",
  },
  {
    img_link: "",
    title: "Telephonic",
    para: " Telephonic medical interpretation services is the service of medical interpreting through the telephone. All telephone conversations from patient to provider are mediated by the medical interpreter, who has the linguistic and industry expertise to be qualified for the job.",
  },
  {
    img_link: "",
    title: "Video Remote",
    para: "Video remote interpreting is the process of interpreting through all video communications. Video remote interpretation is a prime aspect of telehealthcare, especially for doctor's visits, such as follow-ups, treatment plans, and more.",
  },
];
