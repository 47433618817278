import Layout from "components/layout";
import MedicalInterpretation from "components/reusableComponents/Medicalinterpretation/MedicalInterpretation";
import React from "react";
import SEO from "components/seo";

function index() {
  const description = " medical-interpretation";
  const title = "medical-interpretation";
  const keywords = "medical-interpretation";
  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        keywords={keywords}
        slug="/medical-interpretation"
      />
      <MedicalInterpretation />
    </Layout>
  );
}

export default index;
