import Reviews from "components/homepage/reviews";
import WhatChooseUs from "components/homepage/whyChooseUs";
import LocationBanner from "components/locationBanner";
import CommonUse from "components/LocationReusableComp/commonuse";
import SpeceficTranslation from "components/LocationReusableComp/speceficTranslation";
import TrustedTranslation from "components/LocationReusableComp/trustedtranslation";
import React from "react";
import AcadmicInstruction from "../AcadmicInstruction";
import LocalizationMidText from "../localizationMidText";
import TechnicalDocument from "../technicalDocument";
import WhatsAppComp from "../whatsappComp";
import { MedicalInterData, MedicalPreferenceData } from "./config";
import MedicalPreference from "./MedicalPreference";

function MedicalInterpretation() {
  return (
    <>
      <LocationBanner
        backgroundCol="bg-midBlue"
        title="Medical Interpreting Services"
        content="If you want the most secured medical interpreting services, look no further. Tomedes medical interpreters are some of the most experienced in the field, providing critical interpretation services to those in need in 120 languages and 950+ language pairs. This all comes with a 24/7 customer line, 1-year guarantee, and fast delivery."
        formOrangeHead="Looking"
        formWhiteHead="to book a medical
        interpreter?"
      />
      <AcadmicInstruction
        head="Our document translation services are"
        content="We're trusted by Fortune 500 companies and small and medium corporations alike. Put your trust in Tomedes today. Here are some of our 95,000+ satisfied business partners."
        colorHead="Trusted"
        secondHead="by companies and individuals worldwide"
        thirdColorHead="text-LightBlue"
        thirdHead=" "
      />
      <LocalizationMidText
        orangeMidText="certified"
        content="translation services are guaranteed to solve all your submission problems by providing global solutions for your various requirements. We provide certified translations that are authentic representations of your original documents."
      />
      <TrustedTranslation
        headColor="text-lightBlue"
        ColorHead="HIPAA"
        SecondWhite="Compliant Medical Interpreting Services"
        Content="The Health Insurance Portability and Accountability Act of 1996, or HIPAA, is a federal law that requires compliance to national standards, in order to protect patient health information. At Tomedes, the sensitive information of patients, accessed through documentation or interpretation, is never disclosed to anyone else without the patient's consent. Tomedes is HIPAA compliant in all aspects of our operations."
        bottomContent=" "
      />
      <SpeceficTranslation
        backgroundCol="bg-midBlue"
        FirstWhite="What is"
        SecondWhite="Interpreting?"
        ColorHead="Medical"
        headColor="text-orange"
        Content="Medical interpreting is the facilitation of conversations between patients and health providers, for those with limited proficiencies in different languages. Medical interpreters are individuals fluent in two or more languages, as well as accredited with the knowledge of medical interpreting. At Tomedes, you'll find the right medical interpreter, for your one-on-one clinic visits with your doctor, for your medical emergencies, or for your medical trials."
      />
      <CommonUse
        backgroundCol="bg-darkBlue"
        title="Medical Interpreting for Healthcare Institutions"
        para="We're trusted by Fortune 500 companies and small and medium corporations alike. Put your trust in Tomedes today. Here are some of our 95,000+ satisfied business partners."
        data={MedicalInterData}
      />
      <MedicalPreference MedicalPreferenceData={MedicalPreferenceData} />
      <WhatsAppComp
        backgroundCol="bg-darkBlue"
        fHead="Chat"
        secondHead="with us - we're a button click away"
        content="Tomedes has a worldwide team of translators accredited to certify translations of practically any type of document for virtually every jurisdiction. We offer a 100% 1-year guarantee on our certified translation services."
        btn
      />

      <SpeceficTranslation
        getStarted
        backgroundCol="bg-medical-bottom"
        FirstWhite="Translation services made for every"
        ColorHead="Medical"
        SecondWhite="Interpreting in 120 Languages and 950+ Services"
        headColor="text-orange"
        linkName="SEE OUR SUPPORTED LANGUAGES"
        Content="All these types of medical interpretation services can all transform your language into 120 languages and 950+ language pairs. If you're looking for on-demand interpreting services and nationwide medical interpreting services, look no further than Tomedes translation company."
      />
      <TrustedTranslation
        backgroundCol="bg-midBlue"
        headColor="text-orange"
        ColorHead="Trusted"
        FirstWhite="Our"
        SecondWhite="Network of Medical Interpreters"
        Content="We have a trusted network of medical interpreters versed in two or more languages and in the industry of the medical field. Our senior linguists have the experience necessary for the critical role of the medical interpreter. And our project managers can match you with the right medical interpreter that you need."
        bottomContent=" "
      />
      <TechnicalDocument
        colHead="Looking"
        secondHead="to translate medical documents?"
        para="If you're looking to translate medical documents, just contact us, through the form above, through WhatsApp, or through our contact page. We respond with the necessary information within 30 minutes."
      />
      <WhatChooseUs backgroundCol="bg-midBlue" />
      <Reviews backgroundCol="bg-darkBlue" />
    </>
  );
}

export default MedicalInterpretation;
